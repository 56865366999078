import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { StoreProvider, Store } from "../../store/Store";
import SinglePageLayout from "../../components/singlePageLayout"

const Imprint = () => {
  const { dispatch, state } = Store()

  if(state.mobileMenuIsOpen) dispatch({type: "closeMobileMenu"})


  const data = useStaticQuery(graphql`
      query ImprintQuery {
        datoCmsImprint {
            textNode {
                childMarkdownRemark {
                    html
                }
            }
        }
      }`
  )
  return (
    <StoreProvider>
      <div>
        <SinglePageLayout previous={{name: "spoon", link: "/"}} current={{name: "imprint", link: "/imprint"}} next={{name: "Privacy", link: "/privacy"}}>
            <h1>Imprint</h1>
            <p dangerouslySetInnerHTML={{__html: (data.datoCmsImprint.textNode.childMarkdownRemark.html) || ""}}></p>
        </SinglePageLayout>
      </div>
    </StoreProvider>
  )};

export default Imprint
