/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {MobileDetection} from "../../store/mobileDetection"
import "./styles.sass"
import BottomNav from "../bottomNav"

const Layout = ({ children, previous, current, next }) => {
  const { innerHeight } = MobileDetection()


  return (
    <>
    <div>
      <main className="single-page-container" style={{height: `${innerHeight}px`}}>
        <article>
          {children}
        </article>
        <BottomNav previous={previous} current={current} hideOnMobile={['current']} next={next}/>
      </main>
    </div>
    </>
  )
}

export default Layout
