import React from "react"
import SmoothScrollLink from "../smoothScrollLink"
import { Link } from "gatsby"

import "./styles.sass"



const BottomNav = ({ color, previous, current, next, hideOnMobile }) => {
  const doNotDisplay = (type) => {
    if (!!hideOnMobile && hideOnMobile.length > 0) {
      return hideOnMobile.includes(type)
    }
    else {
      return false
    }
  }
  const sideNavClasses = `bottom-nav ${color}`
  const containerId = "section-wrapper"
  return (
    <div className={sideNavClasses}>
      <div className={`previous ${doNotDisplay('previous') ? 'visible-only-desktop' : ""}`}>
        {previous.link === null ?
          <SmoothScrollLink name={previous.name} id={previous.name} containerId={containerId}/> :
          <Link
            to={previous.link}
          >{previous.name}</Link>
        }
        {previous.name !== null &&
        <div className="previous--line" />
        }
      </div>
      <div className={`current ${doNotDisplay('current') ? 'visible-only-desktop' : ""}`}>
        {previous.link === null ?
          <SmoothScrollLink name={current.name} id={current.name} containerId={containerId} /> :
          <Link
            to={current.link}
          >{current.name}</Link>
        }
        {/*{current.name !== null &&*/}
        {/*// <div className="current--line" />*/}
        {/*}*/}
      </div>
      <div className={`next ${doNotDisplay('next') ? 'visible-only-desktop' : ""}`}>
        {previous.link === null ?
          <SmoothScrollLink name={next.name} id={next.name} containerId={containerId} /> :
          <Link
            to={next.link}
          >{next.name}</Link>
        }
        {next.name !== null &&
        <div className="next--line" />
        }
      </div>
    </div>
  )}


BottomNav.defaultProps = {
  color: 'black',
  previous: {
    name: null,
    id: null,
    link: null
  },
  current: {
    name: "work",
    id: "#work",
    link: null
  },
  next: {
    name: "manifesto",
    id: "#manifesto",
    link: null
  }
}

export default BottomNav
